import * as React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
import "@fontsource/raleway/300.css"
import "@fontsource/raleway/500.css"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/hero'
import { Helmet } from "react-helmet"
import { LinkSection, RightSection, LeftSection, HomeButton } from '../components/standardtile'

// styles
const MainBodySection = styled.article`
  max-width: 960px;
  margin: 0 auto 50px;

  > p {
    color: #b13a04;
    max-width: 600px;
    margin: 30px auto 30px auto;
    line-height: 24px;

    @media only screen and (max-width: 900px) {
      padding: 0 20px;
    }
  }
}
`;


// markup
const ABCsPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Opera ABCs | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operaabc" />
          <meta name="description" content="The basics of opera. The terminology & language, the history, the voices and the music." />
        </Helmet>
        <Hero title="Opera ABCs"/>
        <MainBodySection>
          <p>What we are here calling the opera ABCs could in fact be described as the Opera PQRs. Knowledge of operatic terminology and history is both foundational and completely unnecessary. You don't need to know any of this to enjoy opera. It does however help and you can only get so far in your development as a cognoscenti without knowing the basics.</p>
        
          <LinkSection>
            <div>
              <LeftSection>
                <Link to="/operaabc/gettingstarted"><StaticImage src="../images/abcs/gettingstarted.png" alt="Getting Started"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operaabc/gettingstarted">Where to start</Link></h3>

                <p>Your first questions answered. What, where, when and how!</p>

                <HomeButton><Link to="/operaabc/gettingstarted">Get Started</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection>
                <Link to="/operaabc/history"><StaticImage src="../images/abcs/history.png" alt="Baroque opera staging"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operaabc/history">History</Link></h3>

                <p>With 400 years to cover, it's easy to get lost. We hope our timeline will provide you a way into working out Baroque from Romantic, Handel from Glass.</p>

                <HomeButton><Link to="/operaabc/history">Discover History</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection>
                <Link to="/operaabc/voices"><StaticImage src="../images/abcs/voices.png" alt="Baroque opera staging"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operaabc/voices">Voices</Link></h3>

                <p>Singing's what opera is all about and the vast range of voices provide endless depth and variety. But what's a soprano and how do they differ from a Baritone?</p>

                <HomeButton><Link to="/operaabc/voices">Hear the voices</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection>
                <Link to="/operaabc/glossary"><StaticImage src="../images/abcs/glossary.png" alt="Baroque opera staging"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operaabc/glossary">Glossary</Link></h3>

                <p>Opera's a world with its own language. You'll hear plenty of terms tossed about: but what do they all mean? You can look them up right here!</p>

                <HomeButton><Link to="/operaabc/glossary">Get the lingo</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection>
                <Link to="/operaabc/runningtimes"><StaticImage src="../images/abcs/running.png" alt="Baroque opera staging"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operaabc/runningtimes">Running Times</Link></h3>

                <p>Operas can be long. They can also be short. It's worth knowing which before you book your train home. Get the run times on more than 100 operas.</p>

                <HomeButton><Link to="/operaabc/runningtimes">How long?</Link></HomeButton>
              </RightSection>
            </div>
            <div>
              <LeftSection>
                <Link to="/operaabc/arias"><StaticImage src="../images/abcs/arias.png" alt="Baroque opera staging"/></Link>
              </LeftSection>
              <RightSection>
                <h3><Link to="/operaabc/arias">Arias</Link></h3>

                <p>Opera is mostly sung, and that singing is sometimes in the form of an aria. Learn about the different forms, with a bit of history and lots of examples.</p>

                <HomeButton><Link to="/operaabc/arias">Learn about arias</Link></HomeButton>
              </RightSection>
            </div>
          </LinkSection>
        </MainBodySection>
    </Layout>
  )
}

export default ABCsPage
